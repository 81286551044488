import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getBlockedNumbers = params => axios.get(`${getAccountId()}/numbers/blacklist/paginated`, { params })
const createBlockedNumber = formData => axios.post(`${getAccountId()}/numbers/blacklist`, formData)
const updateBlockedNumber = ({ blockedNumberSlug, formData }) => axios.patch(`${getAccountId()}/numbers/blacklist/${blockedNumberSlug}`, formData)
const unblockNumber = blockedNumberSlug => axios.delete(`${getAccountId()}/numbers/blacklist/${blockedNumberSlug}`)

export {
  getBlockedNumbers, createBlockedNumber, updateBlockedNumber, unblockNumber
}