<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="600px"
    persistent
    overlay-color="overlayColor"
  >
    <v-card class="cardBg py-18 px-20 pt-sm-23 pr-sm-35 pb-sm-35 pl-sm-0">
      <v-row no-gutters>
        <v-col cols="12" sm="5" class="pb-8 pr-11">
          <div class="font-large-title text-uppercase dialogTitle--text text-sm-right">
            {{ dialogTitle }}
          </div>
        </v-col>
      </v-row>
      <v-form class="pb-20" ref="form" v-model="validForm">
        <v-row no-gutters>

          <v-col class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-0" cols="12" sm="5" v-if="!blockedNumberSlug">
            <span class="font-normal nameField--text text-sm-right">Type</span>
          </v-col>
          <v-col cols="12" sm="7" v-if="!blockedNumberSlug">
            <ToggleButton
              v-model="typeOption"
              :buttons="typeOptions"
            />
          </v-col>

          <v-expand-transition>
            <v-col cols="12" v-if="!typeOption">
              <v-row no-gutters>
                <v-col class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-0 pt-10" cols="12" sm="5">
                  <span class="font-normal nameField--text text-sm-right">Enter the Number</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="blockedNumber"
                  ></portal-target>
                </v-col>

                <v-col class="pt-10" cols="12" sm="7">
                  <FormField
                    v-model="formData.number"
                    :readonly="!!blockedNumberSlug"
                    :rules="rules"
                    type="number"
                    prefix="+"
                    @keypress="onlyNumbers"
                  >
                    <template #message="{ key, message }">
                      <portal to="blockedNumber" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </FormField>
                </v-col>
              </v-row>
            </v-col>
          </v-expand-transition>

          <v-expand-transition>
            <v-col cols="12" v-if="typeOption">
              <v-row no-gutters>
                <v-col class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-0 pt-10" cols="12" sm="5">
                  <span class="font-normal nameField--text text-sm-right">Number Prefix</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="blockedNumber"
                  ></portal-target>
                </v-col>

                <v-col class="pt-10" cols="12" sm="7">
                  <FormField
                    v-model="formData.prefix"
                    :readonly="!!blockedNumberSlug"
                    :rules="rules"
                    type="number"
                    prefix="+"
                    @keypress="onlyNumbers"
                  >
                    <template #message="{ key, message }">
                      <portal to="blockedNumber" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </FormField>
                </v-col>
              </v-row>
            </v-col>
          </v-expand-transition>

  
          <v-col class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-0 pt-10" cols="12" sm="5">
            <span class="font-normal nameField--text text-sm-right">Scope</span>
          </v-col>
          <v-col class="pt-10" cols="12" sm="7">
            <ToggleButton
              v-model="scopeOption"
              :buttons="scopeOptions"
            />
          </v-col>
          
          <v-expand-transition>
            <v-col cols="12" v-if="scopeOption">
              <v-row no-gutters>
                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-0 pt-10"
                  cols="12" sm="5"
                >
                  <span class="font-normal nameField--text text-sm-right">Select campaign</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="selectCampaignToBlock"
                  ></portal-target>
                </v-col>
  
                <v-col class="pt-10" cols="12" sm="7">
                  <AutocompleteField
                    v-model="formData.campaign"
                    :loading="loadingCampaigns"
                    :items="allCampaigns"
                    item-text="name"
                    item-value="slug"
                    :rules="rules"
                    :multiple="!blockedNumberSlug"
                  >
                    <template #message="{ key, message }">
                      <portal to="selectCampaignToBlock" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>

                    <template #selection="{ item, index }" >
                      <span class="nameField--text pr-4" v-if="index === 0">
                        {{ getCnt() }}
                      </span>
                    </template>
                  </AutocompleteField>
                </v-col>
              </v-row>
            </v-col>
          </v-expand-transition>
        </v-row>
      </v-form>
      
      <div class="d-flex justify-sm-end">
        <CancelButton @click="closeDialog">Cancel</CancelButton>
        
        <ActionButton
          class="ml-5 ml-sm-11"
          :loading="loading"
          @click="submitForm"
        >
          {{ dialogBtnTitle }}
        </ActionButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ToggleButton from '@/components/buttons/ToggleButton'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'
import FormField from '@/components/inputs/FormField'
import AutocompleteField from '@/components/select/AutocompleteField'

import { createBlockedNumber, updateBlockedNumber } from '@/api/block-numbers-routes'
import { getAllCampaigns } from '@/api/campaign-routes'

import { getNameById } from '@/helper/app-helper'

export default {
  name: 'DialogBanNumber',
  inheritAttrs: false,
  components: { ToggleButton, CancelButton, ActionButton, FormField, AutocompleteField },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    blockedNumber: {
      type: Object,
      default: null
    },
    blockedNumberSlug: {
      type: String | Number,
      default: null
    }
  },
  data: () => ({
    loading: false,
    loadingCampaigns: false,
    validForm: true,
    formData: {
      number: null,
      prefix: null,
      campaign: null,
    },
    typeOption: 0,
    typeOptions: [
      { name: 'Number', id: 0 },
      { name: 'Prefix', id: 1 },
    ],
    scopeOption: 0,
    scopeOptions: [
      { name: 'All Campaigns', id: 0 },
      { name: 'Select Campaign', id: 1 },
    ],
    rules: [],
    allCampaigns: [],
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    dialogTitle() {
      return this.blockedNumberSlug ? 'Update' : 'Block a Number'
    },
    dialogBtnTitle() {
      return this.blockedNumberSlug ? 'Update' : 'Block number'
    },
  },
  methods: {
    async loadAllCampaigns() {
      this.loadingCampaigns = true
      
      const { success, payload, message } = await getAllCampaigns()
      
      if (success) {
        this.allCampaigns = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingCampaigns = false
    },
    async submitForm() {
      await this.formValidation()

      if (this.$refs.form.validate()) {
        if (!!this.typeOption) {
          this.formData.number = null
        } else {
          this.formData.prefix = null
        }

        if (this.blockedNumberSlug) {
          this.goToUpdateBlockedNumber()
        } else {
          this.goToCreateBlockedNumber()
        }
      }
    },
    getCnt() {
      const cnt = Array.isArray(this.formData.campaign) ? this.formData.campaign.length : 1

      return cnt === 1
        ? getNameById(this.allCampaigns, this.getCampaignSlug(), 'name', 'find', 'slug')
        : `${cnt} campaigns`
    },
    getCampaignSlug() {
      return Array.isArray(this.formData.campaign) ? this.formData.campaign[0] : this.formData.campaign
    },
    formValidation() {
      this.rules = [
        v => !!v ||  'This field is required'
      ]
    },
    async goToCreateBlockedNumber() {
      this.loading = true
      
      const { success, message } = await createBlockedNumber(this.formData)
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('createSuccess')
        this.closeDialog()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loading = false
    },
    async goToUpdateBlockedNumber() {
      this.loading = true
      
      const { success, payload, message } = await updateBlockedNumber({
        blockedNumberSlug: this.blockedNumberSlug,
        formData: this.formData
      })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('updateSuccess', payload)
        this.closeDialog()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loading = false
    },
    onlyNumbers(event) {
      const regex = new RegExp('^[0-9]+$')
      const key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
    closeDialog() {
      this.show = false
    },
  },
  watch: {
    show(value) {
      if (value) {
        if (this.blockedNumber) {
          this.formData = Object.assign({}, this.blockedNumber)
          this.typeOption = !!this.blockedNumber.prefix
          this.scopeOption = this.blockedNumber.campaign ? 1 : 0
        }
        this.loadAllCampaigns()
      } else {
        this.rules = []
        this.allCampaigns = []
        this.formData = {
          number: null,
          prefix: null,
          campaign: null
        }
        this.typeOption = 0
        this.scopeOption = 0
      }
    },
    success: {
      handler(value) {
        if (value) {
          this.closeDialog()
        }
      }
    },
    scopeOption: {
      handler(value) {
        if (!value) {
          this.formData.campaign = null
        }
      }
    }
  }
}
</script>
