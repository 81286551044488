<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="d-flex flex-wrap align-center justify-end">
      <portal to="pageTitle" :disabled="checkMd">
        <div class="font-page-header pr-5 pr-sm-15 mb-md-15">Manage Blocked Numbers</div>
      </portal>
      
      <ActionOutlinedButton class="mr-5 mr-md-11 mb-4 mb-sm-15" @click="openDialogBanNumber">
        Block a Number
      </ActionOutlinedButton>
      
      <RefreshButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :disabled="loading" @click="refresh" />
      
      <v-spacer />
  
      <SearchField
        v-model="search"
        class="mb-4 mb-sm-15"
        @changedSearch="refresh"
      />
  
      <SelectOnPage
        class="pagination-container paginationColor--text ml-5 ml-sm-8 mb-4 mb-sm-15"
        :readonly="loading"
        @changeOnPage="refresh"
      />
    </div>
    
    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options="options"
        :custom-sort="() => items"
        class="elevation-0 borderNone tableBg rounded-20 borderTable"
        outlined
        disable-pagination
        hide-default-footer
        :loader-height="2"
        :header-props="{ sortIcon: '$sortDownIcon' }"
        :mobile-breakpoint="0"
        @update:options="changeOptions"
      >
        <template #no-data>
          <div class="d-flex">
            <span class="font-normal text-left">No data available</span>
          </div>
        </template>
        
        <template #item.number="{ item }">
          <span class="font-normal text-no-wrap">
            +{{ item.number ? item.number : item.prefix }}
          </span>
        </template>

        <template #item.campaign="{ item }">
          <span class="font-normal text-no-wrap">
            {{ item.campaign ? item.campaign.name : 'All Campaigns' }}
          </span>
        </template>
        
        <template #item.actions="{ item }">
          <TooltipAction bottom message="Edit">
            <template #activator>
              <v-icon class="mr-6" @click="openEditBlockedNumberDialog(item)">$pencilIcon</v-icon>
            </template>
          </TooltipAction>
          
          <TooltipAction bottom message="Unblocked number">
            <template #activator>
              <v-icon @click="openUnblockedNumberDialog(item)">$deleteIcon</v-icon>
            </template>
          </TooltipAction>
        </template>
      </v-data-table>

      <TablePagination
        :loading="loading"
        :dataPagination="dataPagination"
        @input="changePage"
      />

      <div :class="dataPagination.lastPage > 1 ? 'pb-4' : 'pb-26'"></div>
    </div>
    
    <DialogConfirm
      v-model="confirmDialog"
      :loading="loadingAction"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      @actionSuccess="unblockNumber"
    />
    
    <DialogBanNumber
      v-model="dialogBanNumber"
      :blockedNumber="dialogBlockedNumber"
      :blockedNumberSlug="dialogBlockedNumberSlug"
      @createSuccess="refresh"
      @updateSuccess="updateItems"
    />
  </v-container>
</template>

<script>
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import RefreshButton from '@/components/buttons/RefreshButton'
import SearchField from '@/components/inputs/SearchField'
import SelectOnPage from '@/components/select/SelectOnPage'
import TooltipAction from '@/components/tooltip/TooltipAction'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import DialogBanNumber from '@/components/dialog/DialogBanNumber'
import TablePagination from '@/components/pagination/TablePagination'

import { getBlockedNumbers, unblockNumber } from '@/api/block-numbers-routes'
import { getOnPage, changeItemsAfterUpdate, parsePaginationData } from '@/helper/app-helper'

export default {
  name: 'BlockedNumbers',
  components: { ActionOutlinedButton, RefreshButton, SearchField, SelectOnPage, TooltipAction, DialogConfirm, DialogBanNumber, TablePagination },
  data: () => ({
    loading: false,
    loadingAction: false,
    items: [],
    dataPagination: {
      page: 1,
      lastPage: 1
    },
    options: {},
    headers: [
      { text: 'BLOCKED NUMBER', value: 'number', sortId: 'byNumber', class: 'text-no-wrap' },
      { text: 'CAMPAIGN', value: 'campaign', sortId: 'byCampaign' },
      { text: 'ACTIONS', value: 'actions', sortable: false, width: 100, align: 'right' },
    ],
    dialogBanNumber: false,
    dialogBlockedNumber: null,
    dialogBlockedNumberSlug: null,
    confirmDialog: false,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    serverSorting: null,
    search: null,
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
  },
  methods: {
    changeOptions(options) {
      if (!this.loading) {
        this.options = Object.assign({}, options)
      }
    },
    async loadBlockedNumbers() {
      this.loading = true

      const { success, payload, message } = await getBlockedNumbers({
        page: this.dataPagination.page,
        onPage: getOnPage(),
        search: this.search,
        ...this.serverSorting,
      })

      if (success) {
        this.dataPagination = parsePaginationData(payload)
        this.items = payload.data
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    changePage(page) {
      if (page !== this.dataPagination.page) {
        this.dataPagination.page = page
        this.loadBlockedNumbers()
      }
      this.$vuetify.goTo(0)
    },
    openDialogBanNumber() {
      this.dialogBlockedNumberSlug = null
      this.dialogBlockedNumber = null
      this.dialogBanNumber = true
    },
    openEditBlockedNumberDialog(blockedNumber) {
      this.dialogBlockedNumberSlug = blockedNumber.slug
      this.dialogBlockedNumber = {
        number: blockedNumber.number,
        prefix: blockedNumber.prefix,
        campaign: blockedNumber.campaign ? blockedNumber.campaign.slug : null
      }
      this.dialogBanNumber = true
    },
    updateItems(updatedItem) {
      this.items = changeItemsAfterUpdate(this.items, updatedItem)
    },
    openUnblockedNumberDialog(blockedNumber) {
      this.selectedItem = blockedNumber
      this.dialogTitle = 'Unblock Number'
      this.dialogSubTitle = `+${blockedNumber.number ? blockedNumber.number : blockedNumber.prefix }`
      this.dialogMessage = 'Are you sure you want to unblock this number?'
      this.confirmDialog = true
    },
    async unblockNumber() {
      this.loadingAction = true

      const { success, message } = await unblockNumber(this.selectedItem.slug)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.confirmDialog = false
        this.refreshDataAfterDelete()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    refreshDataAfterDelete() {
      if (this.items.length === 1 && this.dataPagination.page !== 1) {
        --this.dataPagination.page
      }

      this.loadBlockedNumbers()
    },
    refresh() {
      this.dataPagination.page = 1
      this.loadBlockedNumbers()
    },
  },
  watch: {
    options: {
      deep: true,
      handler(sortOptions) {
        if (sortOptions.sortBy.length) {
          const sortBy = this.headers.find(item => item.value === sortOptions.sortBy[0])
          this.serverSorting = { [sortBy.sortId]: sortOptions.sortDesc[0] ? 'asc' : 'desc' }
        } else {
          this.serverSorting = null
        }
        this.loadBlockedNumbers()
      },
    },
  },
}
</script>
